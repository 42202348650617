<template>
  <md-sidenav v-if="showMenu !== false && user && modules" class="md-left sp-sidenav md-fixed" ref="sidenavigation"
              :md-swipeable="true">

    <md-whiteframe md-tag="md-toolbar" md-elevation="0" class="sp-sidenav-toolbar">
      <md-layout md-column>
        <div class="sp-sidenav-logo">
          <router-link :to="{ path: '/dashboard' }"></router-link>
        </div>
      </md-layout>
    </md-whiteframe>

    <md-list>
      <template v-for="(item, index) in activeModules" v-if="showModel(item) " >
        <timesheet-list-item :item="item" :active-modules="activeModules" v-if="item.module_name == 'timesheet'"></timesheet-list-item>
        <chat-list-item  :item="item" :modules="modules" v-else-if="item.module_name == 'chat' && user" :chat-app-url="chatAppUrl"></chat-list-item>
        <servappt-list-item  :item="item" :modules="modules" v-else-if="item.module_name == 'servappt' && user"></servappt-list-item>
        <checklist-list-item :item="item" :modules="modules" v-else-if="item.module_name == 'quality_report' && user" ></checklist-list-item>
        <survey-list-item :item="item" :modules="modules" v-else-if="item.module_name == 'surveys' && user  && user.role === 'admin'" ></survey-list-item>
        <enrollments-list-item :item="item" :modules="modules" v-else-if="item.module_name == 'enrollments' && user  && user.role === 'admin'" ></enrollments-list-item>
        <timelog-list-item :item="item" :modules="modules" v-else-if="item.module_name == 'timelog_targethours' && user  && user.role === 'admin'"></timelog-list-item>
        <shop-list-item :item="item" :modules="modules" v-else-if="item.module_name == 'shop' && user"></shop-list-item>
        <object-control-list-item :item="item" :modules="modules" v-else-if="item.module_name == 'object_control' && user"></object-control-list-item>
        <learning-portal-list-item :item="item" :modules="modules" v-else-if="item.module_name == 'learning_portal' && user"></learning-portal-list-item>

        <!-- Default Item -->
        <v-list-item v-else :to="{ path: item.slug }" active-class="v-list-item--active" v-show="isPermission(item.permissions)">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-4">{{ item.title }}</v-list-item-title>
        </v-list-item>

        <v-divider 
          v-if="item.module_name == 'timesheet' ||
          item.module_name == 'chat' ||
          item.module_name == 'servappt' ||
          item.module_name == 'quality_report' ||
          item.module_name == 'surveys' && user  && user.role === 'admin' ||
          item.module_name == 'enrollments' && user  && user.role === 'admin' ||
          item.module_name == 'timelog_targethours' && user  && user.role === 'admin' ||
          isPermission(item.permissions)">
        </v-divider>
      </template>

      <v-list-item :to="{ path: '/users' }" v-if="user.role === 'admin' || user.role === 'coordinator'">
        <v-list-item-icon>
          <v-icon>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
          <v-list-item-title class="ml-4">{{ t('users') }}</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <!--Einstellungen-->
      <v-list>
        <v-list-group v-if="user && user.role === 'admin'" prepend-icon="mdi-cogs">
          <template v-slot:activator>
            <span class="module-title">{{ t('settings') }}</span>
          </template>

          <v-list-item to="/settings/global" class="left-inset">
            <v-list-item-title>{{ t('settings-general') }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/settings/client" class="left-inset">
            <v-list-item-title>{{ t('settings-client') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="moduleEnabled('timesheet')" to="/settings/timetracking" class="left-inset">
            <v-list-item-title>{{ t('settings-time') }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/settings/permission-groups" class="left-inset">
            <v-list-item-title>{{ t('settings-permissiongroups') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="moduleEnabled('ideabox')" to="/settings/ideabox" class="left-inset">
            <v-list-item-title>{{ t('settings-ideabox') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="moduleEnabled('absence')" to="/settings/absences" class="left-inset">
            <v-list-item-title>{{ t('settings-absences') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="canAccessExpenseEmailSettings()" to="/settings/expense-types" class="left-inset">
            <v-list-item-title>{{ t('settings-expensetypes') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="canAccessExpenseEmailSettings()" to="/settings/expense" class="left-inset">
            <v-list-item-title>{{ t('settings-expenseemails') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="moduleEnabled('tasks')" to="/settings/tasks" class="left-inset">
            <v-list-item-title>{{ t('settings-taskcategories') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="moduleEnabled('news')" to="/settings/news-categories" class="left-inset">
            <v-list-item-title>{{ t('settings-newscategories') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="moduleEnabled('jobs')" to="/settings/jobs-categories" class="left-inset">
            <v-list-item-title>{{ t('settings-jobcategories') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="moduleEnabled('timelog_wv') || moduleEnabled('surveys_wv')" to="/settings/webview" class="left-inset">
            <v-list-item-title>{{ t('settings-webview') }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/settings/upload/import" class="left-inset">
            <v-list-item-title>{{ t('settings-apiimport') }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/settings/privacy-policy" class="left-inset">
            <v-list-item-title>{{ t('settings-privacypolicy') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="moduleEnabled('work_report')" to="/settings/work-reports/selection-lists" class="left-inset">
            <v-list-item-title>{{ t('settings-workreports-selectionlists') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="moduleEnabled('work_report')" to="/settings/work-reports/settings" class="left-inset">
            <v-list-item-title>{{ t('settings-workreports-settings') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="canAccessChatSettings()" to="/settings/chat" class="left-inset">
            <v-list-item-title>{{ t('settings-chat') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="moduleEnabled('qc_notifications')" to="/settings/qc-notifications" class="left-inset">
            <v-list-item-title>{{ t('qc-notifications') }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/settings/menu" class="left-inset">
            <v-list-item-title>{{ t('menu') }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
      <!-- Einstellungen END -->

      <!-- Profile START -->
      <v-list-item :to="{ path: '/me' }" v-if="user">
        <v-list-item-icon>
          <v-icon>face</v-icon>
        </v-list-item-icon>
          <v-list-item-title class="ml-4">{{ t('profile') }}</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <!-- Profile END -->

      <!-- Logout END -->
      <md-list-item @click.native="logout">
        <v-list-item-icon>
          <v-icon>exit_to_app</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="ml-4">{{ t('logout') }}</v-list-item-title>
      </md-list-item>
      <!-- Logout END -->
    </md-list>

    <div class="quick-settings">
      <locale-switcher></locale-switcher>
    </div>

    <div style="margin-left: 20px; color: #888888; font-size: 9px;">Version {{ buildId }} ({{ gitId }})</div>

  </md-sidenav>
</template>

<script>
import { isAdmin } from '@/services/UserService'
import { hasPermission } from '@/services/PermissionService'
import { hasModule } from '@/services/LicenseService'
import store from 'store'
import { EventBus } from '@/event-bus'
import auth from '@/auth/index'
import LocaleSwitcher from '@/components/layouts/LocaleSwitcher'
import TimesheetListItem from './list-items/TimesheetListItem.vue'
import ChatListItem from './list-items/ChatListItem'
import ServapptListItem from './list-items/ServapptListItem'
import ChecklistListItem from './list-items/ChecklistListItem'
import SurveyListItem from './list-items/SurveyListItem'
import EnrollmentsListItem from './list-items/EnrollmentsListItem'
import TimelogListItem from './list-items/TimelogListItem'
import ShopListItem from './list-items/ShopListItem.vue'
import ObjectControlListItem from './list-items/ObjectControlListItem'
import LearningPortalListItem from './list-items/LearningPortalListItem'

let BUILDID = process.env.VUE_APP_BUILDID ?? 'local'
let GITCOMMIT = process.env.VUE_APP_GITCOMMIT ?? process.env.VUE_APP_GITINFO
let chatAppUrl = process.env.VUE_APP_CHAT_URL || '/chat'

export default {
  name: 'SideNavigation',
  components: {
    LocaleSwitcher,
    TimesheetListItem,
    ChatListItem,
    ServapptListItem,
    ChecklistListItem,
    SurveyListItem,
    EnrollmentsListItem,
    TimelogListItem,
    ShopListItem,
    ObjectControlListItem,
    LearningPortalListItem
  },
  data() {
    return {
      user: null,
      customIconAuswahlfelder: './../../assets/images/icn-auswahlfelder.svg',
      modules: [],
      buildId: BUILDID,
      gitId: GITCOMMIT ? GITCOMMIT.slice(0, 8) : 'n/a',
      chatAppUrl,
      activeModules: null,
    }
  },
  mounted() {
    this.updateMenu()
    this.getUser()
    EventBus.$on('module-config-changed', function () {
      this.updateMenu()
    }.bind(this))
  },
  props: {
    dataLoaded: {
      type: Boolean,
      required: false,
      default: false
    },
    menu: {
      type: Boolean,
      required: false,
      default: true
    },
    back: {
      type: Boolean,
      required: false,
      default: false
    },
    path: {
      type: String,
      required: false,
      default: ''
    },
    event: {
      required: false,
      default: false
    },
    cancel: {
      required: false,
      default: false
    },
    cancelLeft: {
      required: false,
      default: false
    },
    ok: {
      type: Boolean,
      required: false,
      default: false
    },
    edit: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    showMenu() {
      return (!this.back && !this.cancel && !this.cancelLeft)
    }
  },
  methods: {
    t: function (key) {
      return this.$t('navigation.' + key);
    },
    getUser() {
      this.user = store.get('user')
    },
    updateMenu() {
      let modules = store.get('modules')
      this.activeModules = store.get('userModules')
      let moduleNames = []

      modules.forEach(item => {
        if ( item.active === true) {
          moduleNames.push(item.module_name)
        }
      });

      this.modules = moduleNames
    },
    showModel(item) {
      return this.moduleEnabled(item.module_name) && item.backend && item.active && item.main_item && this.checkPermission(item.permissions)
    },
    isPermission(permissionString) {
      // Check if the permissionString is null or empty
      if (permissionString === null || permissionString.trim() === '') {
        return true; // return true if permissions not defined
      }

      // Split the permissionString into an array of permissions
      let permissions = permissionString.split(",");

      // Check if any of the permissions exist in the system
      return hasPermission(permissions);
    },
    moduleEnabled(string) {
      return hasModule(string)
    },
    canAppointmentsSee() {
      return hasPermission('servappt_appointment_manage') || isAdmin()
    },
    canRequestsSee() {
      return hasPermission('servappt_request_manage') || isAdmin()
    },
    canAreaSee() {
      return hasPermission('servappt_area_manage') || isAdmin()
    },
    canAccessExpenseEmailSettings() {
      return hasModule('expense') && (hasPermission(['expense_settings_email']) || isAdmin())
    },
    canAccessChatSettings() {
      return hasModule('chat') && isAdmin()
    },
    logout() {
      auth.logout()
    },
    toggle() {
      if (this.$refs.sidenavigation) {
        this.$refs.sidenavigation.toggle()
      }
    },
    checkPermission(permissionString) {
      if (permissionString == null) {
        return true;
      }

      let permissionArray = permissionString.split(",")
      return hasPermission(permissionArray) || isAdmin()
    }
  }
}
</script>
