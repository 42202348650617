import Vue from 'vue'
import VueRouter from 'vue-router'
import store from 'store'

import AppLogout from '@/views/AppLogout'
import LoginCallback from '@/views/LoginCallback'
import Dashboard from '@/views/Dashboard'
import PasswordReset from '@/views/PasswordReset'
import PasswordResetToken from '@/views/PasswordResetToken'

import absencesRoutes from '@/router/routes/absences'
import chatRoutes from '@/router/routes/chat'
import customerNotesRoutes from '@/router/routes/customer-notes'
import customersRoutes from '@/router/routes/customers'
import documentsRoutes from '@/router/routes/documents'
import emergencyContactsRoutes from '@/router/routes/emergency-contacts'
import enrollmentsRoutes from '@/router/routes/enrollments'
import expensesRoutes from '@/router/routes/expense'
import galleriesRoutes from '@/router/routes/galleries'
// import groupsRoutes from '@/router/routes/groups'
import ideaboxRoutes from '@/router/routes/ideabox'
import instructionsRoutes from '@/router/routes/instructions'
import jobsRoutes from '@/router/routes/jobs'
import learningPortalRoutes from '@/router/routes/learning-portal'
import menuRoutes from '@/router/routes/menu'
import newsRoutes from '@/router/routes/news'
import objectControlRoutes from '@/router/routes/object-control'
import privacyPolicyRoutes from '@/router/routes/privacy-policy'
import qCNotificationsRoutes from '@/router/routes/qcnotifications'
import qualityReportRoutes from '@/router/routes/quality-report'
import servapptRoutes from '@/router/routes/servappt'
import settingsRoutes from '@/router/routes/settings'
import shopRoutes from '@/router/routes/shop'
import surveysRoutes from '@/router/routes/surveys'
import tasksRoutes from '@/router/routes/tasks'
import timelogRoutes from '@/router/routes/timelog'
import timesheetApprovalRoutes from '@/router/routes/timesheet-approval'
import usersRoutes from '@/router/routes/users'
import workReportRoutes from '@/router/routes/work-report'

import ModulesConfiguration from '@/views/ModulesConfiguration'
import ProfileIndex from '@/views/ProfileIndex'
import PushConfiguration from '@/views/PushConfiguration'

Vue.use(VueRouter)

const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/me',
        name: 'ProfileIndex',
        component: ProfileIndex,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: AppLogout,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: false
            }
        }
    },
    {
        path: '/oidc-callback',
        name: 'LoginCallback',
        component: LoginCallback,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: false
            }
        }
    },
    {
        path: '/module-configuration',
        name: 'ModuleConfiguration',
        component: ModulesConfiguration,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/push-configuration',
        name: 'PushConfiguration',
        component: PushConfiguration,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/password-reset',
        name: 'PasswordReset',
        component: PasswordReset,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/password-reset/:token?',
        name: 'PasswordResetToken',
        component: PasswordResetToken,
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    ...absencesRoutes,
    ...chatRoutes,
    ...customerNotesRoutes,
    ...customersRoutes,
    ...documentsRoutes,
    ...emergencyContactsRoutes,
    ...enrollmentsRoutes,
    ...expensesRoutes,
    ...galleriesRoutes,
    // ...groupsRoutes,
    ...ideaboxRoutes,
    ...instructionsRoutes,
    ...jobsRoutes,
    ...learningPortalRoutes,
    ...menuRoutes,  
    ...newsRoutes, 
    ...objectControlRoutes,
    ...privacyPolicyRoutes,
    ...qCNotificationsRoutes,
    ...qualityReportRoutes,
    ...servapptRoutes,
    ...settingsRoutes,
    ...shopRoutes,
    ...surveysRoutes,
    ...tasksRoutes,
    ...timelogRoutes,
    ...timesheetApprovalRoutes,    
    ...usersRoutes,
    ...workReportRoutes,
    {
        path: '*',
        name: 'NotFound',
        redirect: {
            name: 'Dashboard'
        },
        meta: {
            accessConfig: {
                moduleName: null,
                requiresAuth: false
            }
        }
    }
]

// check if module allowed/active
function accessModule(moduleName) {

    let userModules = store.get('userModules')
    let active = false;
    // Check if moduleName is a string or an array
    const isString = typeof moduleName === "string";
    const isArray = Array.isArray(moduleName);

    for (let module of userModules) {
        // Check if module_name matches with moduleName (string)
        if (isString && moduleName === module.module_name) {
            active = module.active;
            break; // No need to continue iterating if match found
        }

        // Check if module_name is included in moduleName (array)
        if (isArray && moduleName.includes(module.module_name)) {
            active = module.active;
            break; // No need to continue iterating if match found
        }
    }

    return active;  
}

// check if authenticated
function isAuthenticated(to, from, next) {
    return store.get('user')
}

const router = new VueRouter({
    mode: 'history',
    base: '',
    routes
})

// check route config
router.beforeEach((to, from, next) => {
    let toConfig = to.meta.accessConfig

    // check if config defined
    if (typeof toConfig !== 'undefined') {

        // check auth
        //let isAuth = isAuthenticated(to, from, next);
        //if (!isAuth) {
        //     console.log('nav staring login oidc')
        //     Auth.loginOidc(to.path)
        //     return;
        //}

        // check if module accessible
        if (typeof toConfig.moduleName !== 'undefined' && toConfig.moduleName) {
            let isAccessible = accessModule(toConfig.moduleName);
            if (!isAccessible) {
               next({name: "Dashboard"}) // redirect to safety route if module is not on the list
               return;
            }
        }

        next(); // continue

    } else {
        next(); // continue if no config
    }

})

export default router
