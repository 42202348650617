<template>
  <div>
    <md-toolbar class="sp-main-toolbar">

      <!-- LEFT -->

      <!-- Hamburger button -->
      <md-button class="md-icon-button" @click.native="menuClicked" v-if="showMenu">
        <md-icon>menu</md-icon>
      </md-button>

      <!-- Back button -->
      <span v-if="back && dataLoaded">
        <span v-if="path">
          <router-link tag="button" class="md-button md-icon-button" :to="{ path: path }">
            <md-icon>arrow_back</md-icon>
          </router-link>
        </span>
        <span v-if="event">
          <md-button class="md-icon-button" @click.native="backClicked">
            <md-icon>arrow_back</md-icon>
          </md-button>
        </span>
      </span>

      <span v-if="cancelLeft && dataLoaded">
        <span v-if="path">
          <router-link tag="button" class="md-button md-icon-button" :to="{ path: path }">
            <md-icon>close</md-icon>
          </router-link>
        </span>
        <span v-if="event">
           <md-button class="md-icon-button" @click.native="cancelLeftClicked()">
            <md-icon>close</md-icon>
          </md-button>
        </span>
      </span>

      <!-- CENTER -->

      <h2 v-if="dataLoaded" class="md-title" style="flex: 1">
        <slot></slot>
      </h2>

      <!-- RIGHT -->

      <!-- Cancel button -->
      <md-button v-if="cancel && dataLoaded" class="md-icon-button" @click.native="cancelClicked">
        <md-icon>close</md-icon>
      </md-button>

      <!-- Edit button -->
      <md-button v-if="search" class="md-icon-button" @click.native="searchClicked">
        <md-icon>search</md-icon>
      </md-button>

      <!-- Ok button -->
      <md-button v-if="ok" class="md-icon-button" @click.native="okClicked">
        <md-icon>check</md-icon>
      </md-button>

    </md-toolbar>

    <!-- Sidenavigation -->
    <side-navigation ref="sidenav"></side-navigation>

  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import router from '@/router'
import SideNavigation from '@/components/menu/SideNavigation'

export default {
  name: 'toolbar',
  components: {
    SideNavigation
  },
  props: {
    dataLoaded: {
      type: Boolean,
      required: false,
      default: false
    },
    menu: {
      type: Boolean,
      required: false,
      default: true
    },
    back: {
      type: Boolean,
      required: false,
      default: false
    },
    path: {
      type: String,
      required: false,
      default: ''
    },
    event: {
      required: false,
      default: false
    },
    cancel: {
      required: false,
      default: false
    },
    cancelLeft: {
      required: false,
      default: false
    },
    ok: {
      type: Boolean,
      required: false,
      default: false
    },
    edit: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    showMenu() {
      return (!this.back && !this.cancel && !this.cancelLeft)
    }
  },
  methods: {
    menuClicked() {
      if (this.$refs.sidenav) {
        this.$refs.sidenav.toggle()
      }
    },
    cancelClicked(event) {
      if (event) {
        EventBus.$emit('cancel')
      } else {
        router.push(this.cancel)
      }
    },
    cancelLeftClicked() {
      EventBus.$emit('cancel')
    },
    okClicked() {
      EventBus.$emit('ok')
    },
    editClicked() {
      EventBus.$emit('edit')
    },
    backClicked() {
      EventBus.$emit('back', this.event)
    },
    searchClicked() {
      EventBus.$emit('search')
    }
  }
}
</script>

<style scoped>
.md-list-item-expand:before, .md-list-item-expand:after {
  content: none;
}
.quick-settings {
  border-top: var(--v-primary-base) 5px solid;
  overflow: hidden;
}

.md-sidenav-content .md-toolbar {
  background-color: #fff;
}
</style>
