import AbsencesIndex from '@/views/AbsencesIndex'
import AbsencesEdit from '@/views/AbsencesEdit'
import SettingsAbsences from '@/views/SettingsAbsences'

const absencesRoutes = [
    {
        path: '/absences',
        name: 'AbsencesIndex',
        component: AbsencesIndex,
        meta: {
            accessConfig:  {
                moduleName: ['absence'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/absences/:id?',
        name: 'AbsencesEdit',
        component: AbsencesEdit,
        meta: {
            accessConfig:  {
                moduleName: ['absence'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/absences',
        name: 'SettingsAbsences',
        component: SettingsAbsences,
        meta: {
            accessConfig:  {
                moduleName: ['absence'],
                requiresAuth: true
            }
        }
    }
]

export default absencesRoutes
