<template>
    <v-list>
      <v-list-group v-model="isExpanded" :prepend-icon="item.icon">
        <template v-slot:activator>
          <span class="module-title">{{ item.title }}</span>
        </template>
  
        <v-list-item v-if="moduleExists('timelog_stamp')" to="/timestamps" class="left-inset">
          <v-list-item-title>{{ $t('navigation.timelog-stamp') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item v-if="moduleExists('timelog_basic')" to="/time" class="left-inset">
          <v-list-item-title>{{ $t('navigation.timelog-timelogs') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item v-if="moduleExists('timelog_locktimemode')" to="/time-lock" class="left-inset">
          <v-list-item-title>{{ $t('navigation.timelog-timelock') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item v-if="moduleExists('timelog_report') && isAdmin" to="/time-sheets" class="left-inset">
          <v-list-item-title>{{ $t('navigation.timelog-reports') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item v-if="moduleExists('timelog_reportoverview') && isAdmin" to="/time-sheets-all" class="left-inset">
          <v-list-item-title>{{ $t('navigation.timelog-reportoverview') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item v-if="moduleExists('time_sheets_month') && isAdmin" to="/time-sheets-month" class="left-inset">
          <v-list-item-title>{{ $t('navigation.timelog-salaryreport') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item v-if="moduleExists('timelog_proof_report')" to="/timelog-proof-report" class="left-inset">
          <v-list-item-title>{{ $t('navigation.timelog-proof-report') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item v-if="moduleExists('timelog_expense_report')" to="/timelog-expense-report" class="left-inset">
          <v-list-item-title>{{ $t('navigation.timelog-expense-report') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item v-if="moduleExists('timelog_approval')" to="/timesheet-approval" class="left-inset">
          <v-list-item-title>{{ $t('navigation.timesheet') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item v-if="canAccessSettings()" to="/settings/timelog" class="left-inset">
          <v-list-item-title>{{ $t('navigation.settings') }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </template>
  
  <script>
  import { isAdmin } from '@/services/UserService'

  export default {
    name: "TimesheetListItem",
    props: {
      item: {
        type: Object,
        required: false,
        default: () => ({})
      },
      activeModules: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    data() {
      return {
        isExpanded: false
      }
    },
    mounted() {
      const timesheetModules = [
        'timelog_qr',
        'timelog_basic',
        'timelog_report',
        'timelog_proof_report',
        'timesheet'
      ]

      if (this.$route.meta.accessConfig.moduleName) {
        this.isExpanded = timesheetModules.includes(this.$route.meta.accessConfig.moduleName[0]);
      } else {
        this.isExpanded = this.$route.path === '/timelog-expense-report'
      }  
    },
    methods: {
      moduleExists(moduleName) {
        return this.activeModules.some(module => module.module_name === moduleName);
      },
      canAccessSettings() {
        return this.moduleExists('timelog_basic') && isAdmin()
      }
    }
  }
  </script>
  