<template>
  <div class="user-filter-widget">
    <v-autocomplete
      class="custom-autocomplete"
      v-model="selectedEmployee"
      :loading="loading"
      :items="items"
      :item-text="getItemText"
      item-value="id"
      :search-input.sync="search"
      filled
      hide-no-data
      :label="label"
      append-icon="mdi-magnify"
      return-object
      :disabled="readonly"
      clearable
      :rules="rules"
      :spellcheck="false"
      :data-cy="dataCy"
      hide-details="auto"
    >
      <template v-slot:item="{ item }">
        <v-list-item-avatar>
          <v-lazy v-if="item.profile_image !== ''" class="user-profile-image">
            <img
              v-if="!failedImages.includes(item.id)"
              :src="baseUrl + '/api/users/image/' + item.id + '/' + item.profile_image"
              @error="imageLoadError(item.id)" 
            />
            <span v-else class="broken-image"><v-icon>mdi-image-off</v-icon></span>
          </v-lazy>
          <span v-else class="user-profile-image user-placeholder"><v-icon>mdi-account-circle</v-icon></span>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ getItemText(item) }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ item.role ? $t('components.content-filter-employee-users'+ "." + item.role) : '' }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="includeInactive && !item.active">
          {{ $t('components.content-filter-employee-search.inactive-title') }}
        </v-list-item-action>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { CancellingHttp } from '@/infrastructure/communication'
import i18n from '@/i18n'

export default {
  name: "UserFilterWidget",
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    value: {},
    label: {
      type: String,
      default: () => i18n.t('components.content-filter-employee-search.label')
    },
    includeInactive: {
      type: Boolean,
      default: () => false,
    },
    preselected: null,
    permissionGroup: {
      default: null,
      required: false
    },
    dataCy: {
      type: String,
      default: 'user-search-filter'
    }
  },
  data() {
    return {
      com: new CancellingHttp(),
      abortController: null,
      loading: false,
      items: [],
      search: null,
      selectedEmployee: null,
      baseUrl: process.env.VUE_APP_API_BASE_URL ?? '',
      failedImages: []
    }
  },
  watch: {
    selectedEmployee(val) {
      this.$emit('input', val)
      this.$emit('item-selected', val?.id, val)
    },
    search(val) {
      val && val !== this.getItemText(this.selectedEmployee) && this.querySelections(val)
    },
  },
  created() {
    if (this.preselected) {
      this.items.push(this.preselected)
      this.selectedEmployee = this.preselected
    }
  },
  methods: {
    getItemText(item) {
      if (!item) {
        return ''
      }
      if (item.display_name) {
        return item.display_name
      }
      return `${item.firstname} ${item.name}`
    },
    async querySelections(val) {
      if (val !== '') {
        let url = 'users?q=' + encodeURIComponent(val.replace(',', ''))
        if (this.permissionGroup) {
          url = 'app/customer_notes/users?q=' + encodeURIComponent(val.replace(',', ''))
        }
        url = url + '&onlyActive=' + (this.includeInactive ? 'false' : 'true')
        if (this.permissionGroup) {
          url = url + '&permission_groups=' + this.permissionGroup
        }
        this.loading = true
        let response = await this.com.get(url)
        if (response) {
          this.items = response.sort((a, b) =>
              a.display_name.localeCompare(b.display_name, undefined, { sensitivity: 'accent' })
          )
          this.loading = false
        }
      }
    },
    imageLoadError(id) {
      this.failedImages.push(id)
    }
  }
}
</script>

<style scoped lang="scss">
.custom-autocomplete {

  &::v-deep .v-progress-linear {
    top: calc(100% - 2px);
  }
}

.user-profile-image {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.user-placeholder i {
  position: absolute;
  top: -1px;
  left: -1px;
  font-size: 46px;
  color: rgba(0, 0, 0, 0.3);
}

.broken-image i {
  color: rgba(0, 0, 0, 0.3);
  padding: 7px;
}
</style>
